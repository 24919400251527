// Here you can add other styles
.rdrDefinedRangesWrapper {
  display: none;
}
.rdrCalendarWrapper {
  display: table!important;
}
.notes-container p {
  margin-bottom: 0;
}
.auto-z-index {
  z-index: 0;
}

.protocol-box {
  padding: 1em;
  background-color: #CCC;
  border-radius: 10px;
  hr:last-child {
    display: none;
  }
}
